<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router/auto";
import useCarriers from "../../../hooks/useCarriers.js";
import { setPageTitle } from "@/utils/pageTitle";
import CarrierLayout from "@/layouts/CarrierLayout.vue";
import useClients from "@/hooks/useClients.js";
import { useToast } from "vue-toastification";
import { shortDateWithTimeTz } from "@/utils/dateUtils.ts";
import { useUserStore } from "@/stores/user";

const route = useRoute();
const clientAccount = ref(false);
const newInvite = ref("");
const clientId = ref(null);
const carrierTypes =  [{title:'Freight', value: 'freight'}, {title: 'Parcel',value:'parcel'}];
const newParentCarrier = ref({
  name: "",
  carrier_type: "",
  nickname: "",
  parent_id: "",
});

const toast = useToast();
const userStore = useUserStore();

const { fetchCarrier, carrier, createCarrier } = useCarriers();
const { users, fetchClientUsers, toggleUserStatus, deleteClientUser, fetchClientInvites, invites, deleteClientInvite, sendClientInvite } = useClients();

onMounted(async () => {
  await fetchCarrier(route.params.id);
  setPageTitle(`Carrier // ${carrier.value.id}`);
  newParentCarrier.value.parent_id = carrier.value.id;
  // check if the carrier has a client
  if (carrier.value.client_id) {
    clientAccount.value = true;
    clientId.value = carrier.value.client_id;
    await fetchClientUsers(carrier.value.client_id);
    await fetchClientInvites(carrier.value.client_id);
    console.log("clientAccount:", users.value);
  } else {
    clientAccount.value = false;
  }
});


const handleUserStatus = async (id, status) => {
  const res = await toggleUserStatus(id, { active : status});
  if (res) {
    await fetchClientUsers(clientId.value);
    const msg = status ? "User Enabled." : "User Disabled.";
    toast.success(msg);
  }
};

const deleteUser = async(id) => {
  const res = await deleteClientUser(id);
  if (res) {
    await fetchClientUsers(clientId.value);
  }
};

const deleteInvite = async (id) => {
  const res = await deleteClientInvite(clientId.value, id);
  if (res) {
    fetchClientInvites(clientId.value);
  }
};

const sendInvite = async () => {
  if (!newInvite.value) {
    toast.error("Email is required.");
    return;
  }

  const res = await sendClientInvite(clientId.value, { email: newInvite.value });
  if (res) {
    await fetchClientInvites(clientId.value);
    newInvite.value = "";
  }
};

const save = async () => {
  await createCarrier(newParentCarrier.value);
}
</script>

<template>
  <CarrierLayout v-if="carrier" :carrier="carrier" title="Users">
    <v-container class="bg-surface-light h-100" variant="flat">
      <h1 class="mb-4">User Management</h1>
      <div v-if="clientAccount">
        <v-card class="pa-4">
          <v-card-title class="mb-4 text-center">Users</v-card-title>

          <v-row class="table-header">
            <v-col cols="1">Status</v-col>
            <v-col cols="4">Email</v-col>
            <v-col cols="2">Name</v-col>
            <v-col cols="2">Last Login</v-col>
            <v-col cols="3"></v-col>
          </v-row>

          <v-row v-if="users?.length > 0" v-for="(user, index) in users" :key="index" :class="users.length - 1 == index ? '' : 'table-row'" align="center">
            <v-col cols="1">
              <div class="badge badge-pill" :class="user.active ? 'badge-success' : 'badge-danger'">{{ user.active ? "Active" : "Disabled" }}</div>
            </v-col>
            <v-col cols="4">{{ user.email }}</v-col>
            <v-col cols="2">{{ user.name }}</v-col>
            <v-col cols="2">{{ shortDateWithTimeTz(user?.last_login_at) }}</v-col>
            <v-col cols="3">
              <v-btn variant="text" :color="user.active? 'red' : 'green'" @click="handleUserStatus(user.id, !user.active)">{{ user.active ? "Disable" : "Enable" }}</v-btn>
              <v-btn variant="text" color="red" @click="deleteUser(user.id)">Delete</v-btn>
            </v-col>
          </v-row>
          <v-row v-else >
            <v-col class="text-center">
              <span>No users yet. Invite some below.</span>
            </v-col>
          </v-row>
        </v-card>

        <v-card class="pa-4 mt-4">
          <v-card-title class="mb-4 text-center">Pending Invites</v-card-title>
          <v-row>
            <v-col cols="4" class="header-col">Email</v-col>
            <v-col cols="4" class="header-col">Sent</v-col>
            <v-col cols="4" class="header-col"></v-col>
          </v-row>

          <v-row v-if="invites?.length > 0" v-for="(invite, index) in invites" :key="index" class="table-row" align="center">
            <v-col cols="4">{{ invite.email }}</v-col>
            <v-col cols="4">{{ shortDateWithTimeTz(invite.created_at) }}</v-col>
            <v-col cols="4">
              <v-btn text color="red" variant="text" @click="deleteInvite(invite.id)">Delete</v-btn>
            </v-col>
          </v-row>

          <v-row v-else >
            <v-col class="text-center">
              <span>No pending Invites</span>
            </v-col>
          </v-row>

          <v-row class="mt-10 table-footer">
            <v-col cols="8">
              <v-text-field v-model="newInvite" label="Enter an email to invite" variant="outlined" hide-details
                density="compact"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-btn color="grey" size="small" @click="sendInvite">Send Invite</v-btn>
            </v-col>
          </v-row>

        </v-card>
      </div>

      <div v-else>
        <v-card v-if="userStore.user.permissions.create_carrier">
          <v-card-text>
            <v-text-field
              v-model="newParentCarrier.name"
              density="compact"
              variant="outlined"
              label="Carrier Name"
              hide-details
            />

            <v-text-field
              v-model="newParentCarrier.slug"
              density="compact"
              variant="outlined"
              label="Carrier Nickname"
              hide-details
              class="my-4"
            />

            <v-autocomplete
              v-model="newParentCarrier.carrier_type"
              :items="carrierTypes"
              density="compact"
              variant="outlined"
              hide-details
              label="Carrier Type"
            ></v-autocomplete>

            <v-text-field
              v-model="newParentCarrier.parent_id"
              density="compact"
              variant="outlined"
              label="Default Carrier Id"
              hide-details
              disabled
              class="my-4"
            />

            <v-btn color="success" @click="save">Save</v-btn>
          </v-card-text>
        </v-card>
        <div v-else>
          This carrier is not set up with access to the carrier portal. Contact a <strong>{{userStore.user.tenant.name}}</strong> admin to enable this feature.
        </div>
      </div>
    </v-container>
  </CarrierLayout>
</template>

<style scoped>

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge-success {
    background-color: green;
    color: white;
}

.badge-danger {
    background-color: red;
    color: white;
}


.header-col {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    font-weight: bold;
}

.table-row {
    border-bottom: 1px solid #eee;
}

.table-footer {
    margin-top: 20px;
}

.table-header {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    font-weight: bold;
}

.table-row {
    border-bottom: 1px solid #eee;
}

.status-avatar {
    display: inline-block;
}

.status-dot {
    background-color: green;
    border-radius: 10%;
    padding: 3px;
    font-size: 13px;
    text-align: center;
    width: 50px;
    height: 25px;
    color: white;
}
</style>